<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog group="dialogImportProposal" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Importar Propostas'"
      :modal="true"
      :closable="false"
    >
      <form
        name="importProposalForm"
        class="p-col-12"
        @submit.prevent="moveProposals"
        style="min-height: 15vh"
      >
        <p>
          <b>Proposta {{ propsalSubject }}</b>
        </p>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-col-5 p-md-5 p-mt-4">
            <span class="p-float-label">
              <InputNumber
                name="proposalById"
                :useGrouping="false"
                v-model="getProposalId"
                :min="1"
                v-bind:class="['form-control']"
                @keyup.enter="getProposalById"
              >
              </InputNumber>
              <label for="proposalById"
                >Introduza o Id da proposta a importar</label
              >
            </span>
          </div>
          <div class="p-field p-col-1 p-md-1 p-mt-4">
            <Button
              icon="pi pi-search"
              class="p-button-rounded"
              @click="getProposalById"
            />
          </div>

          <div class="p-col-5 p-md-5 p-mt-4">
            <span class="p-float-label">
              <InputText
                name="searchProposal"
                :useGrouping="false"
                v-model="searchProposalTerm"
                v-bind:class="['form-control']"
                @keyup.enter="searchProposal"
              >
              </InputText>
              <label for="searchProposal">Pesquisar Proposta</label>
            </span>
          </div>
          <div class="p-field p-col-1 p-md-1 p-mt-4">
            <Button
              icon="pi pi-search"
              class="p-button-rounded"
              @click="searchProposal"
            />
          </div>

          <div class="p-field p-col-12 p-md-12 p-error" v-if="errorMsg">
            {{ errorMsg }}
          </div>
          <div class="p-col-12" v-if="proposalList.length > 0">
            <h5>
              Selecione as Propostas a Importar para Dentro do W4M_{{
                proposalId
              }}:
            </h5>
            <div v-for="proposal in proposalList" :key="proposal.id">
              <div v-if="proposal.id != proposalId">
                <Checkbox
                  v-model="selectedProposals"
                  :binary="false"
                  :value="proposal.id"
                  :id="proposal.id"
                  :inputId="proposal.id"
                  name="proposalsSelected"
                />
                <div>
                  <div class="p-d-flex p-ai-center p-mt-1">
                    <div class="p-col-2"><b>Id</b></div>
                    <div class="p-col-10">W4M_{{ proposal.id }}</div>
                  </div>
                  <div class="p-d-flex p-ai-center p-mt-1">
                    <div class="p-col-2"><b>Estado</b></div>
                    <div class="p-col-10">
                      {{
                        proposal.status ? proposal.proposal_status.name : null
                      }}
                    </div>
                  </div>
                  <div class="p-d-flex p-ai-center p-mt-1">
                    <div class="p-col-2"><b>Cliente</b></div>
                    <div class="p-col-10">
                      {{ getEmailInfo(proposal.client_email) }}
                    </div>
                  </div>
                  <div class="p-d-flex p-ai-center p-mt-1">
                    <div class="p-col-2"><b>Assunto</b></div>
                    <div class="p-col-10">{{ proposal.subject }}</div>
                  </div>
                  <div
                    class="p-d-flex p-ai-center p-mt-1"
                    v-if="proposal.created_at"
                  >
                    <div class="p-col-2"><b>Criado</b></div>
                    <div class="p-col-10">
                      {{
                        proposal.created_by == 0
                          ? "Intranet"
                          : proposal.proposal_created_user.username
                      }}
                      | {{ dateTime(proposal.created_at) }}
                    </div>
                  </div>
                  <div
                    class="p-d-flex p-ai-center p-mt-1"
                    v-if="proposal.updated_at"
                  >
                    <div class="p-col-2"><b>Atualizado</b></div>
                    <div
                      class="p-col-10"
                      v-if="
                        proposal.updated_at &&
                        proposal.updated_at != proposal.created_at
                      "
                    >
                      {{
                        proposal.updated_by == 0
                          ? "Intranet"
                          : proposal.proposal_updated_user.username
                      }}
                      | {{ dateTime(proposal.created_at) }}
                    </div>
                  </div>
                  <div
                    class="p-d-flex p-ai-center p-mt-1"
                    v-if="proposal.assigned_to"
                  >
                    <div class="p-col-2"><b>Responsável</b></div>
                    <div class="p-col-10">
                      {{ proposal.proposal_assigned_user.username }}
                    </div>
                  </div>
                  <div
                    class="p-d-flex p-ai-center p-mt-1"
                    v-if="proposal.priority"
                  >
                    <div class="p-col-2"><b>Prioridade</b></div>
                    <div class="p-col-10">
                      {{ proposal.proposal_priority.name }}
                    </div>
                  </div>
                  <div
                    class="p-d-flex p-ai-center p-mt-1"
                    v-if="proposal.category"
                  >
                    <div class="p-col-2"><b>Categoria</b></div>
                    <div class="p-col-10">
                      {{ proposal.proposal_category.name }}
                    </div>
                  </div>

                  <div class="p-col-6 p-mt-1">
                    <Button
                      :label="'Ver Ticket a Importar'"
                      icon="pi pi-eye"
                      @click="viewProposal(proposal.id)"
                    />
                  </div>
                  <divider />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          v-if="selectedProposals.length > 0"
          :label="'Importar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="moveProposals"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import proposalService from "../../services/proposal.service";
import { getOnlyDate, getOnlyTime } from "../../helpers/helpers";
export default {
  name: "importProposals",
  props: ["showToast", "show", "proposalId", "propsalSubject"],
  watch: {},
  data() {
    return {
      getProposalId: null,
      searchProposalTerm: null,
      proposalList: [],
      selectedProposals: [],
      errorMsg: null,
    };
  },
  created() {},
  methods: {
    cancel() {
      this.reset();
      return this.$emit("cancel");
    },
    reset() {
      this.getProposalId = null;
      this.searchProposalTerm = null;
      this.proposalList = [];
      this.selectedProposals = [];
      this.errorMsg = null;
    },
    moveProposals() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        let message = `Tem a certeza que pretende importar as propostas ${this.selectedProposals.join(
          ", "
        )} para a Proposta W4M_${this.proposalId}?`;
        message += `\nEste processo é irreversível.`;
        this.$confirm.require({
          key: "dialogImportProposal",
          header: "Importar Propostas",
          message: message,
          icon: "pi pi-question-circle",
          acceptLabel: "Sim",
          acceptIcon: "pi pi-check",
          acceptClass: "p-button-success p-button p-component",
          rejectLabel: "Não",
          rejectIcon: "pi pi-times",
          rejectClass: "p-button-danger p-button p-component",
          accept: () => {
            return this.importProposals();
          },
          reject: () => {},
        });
      });
    },
    importProposals() {
      return proposalService
        .importProposals(this.proposalId, {
          proposals_import: this.selectedProposals,
        })
        .then((response) => {
          if (!response) {
            if (this.showToast) {
              this.$toast.add({
                severity: "error",
                summary: "Erro ao importar propostas",
                detail: "",
                life: 3000,
              });
            }
            return this.$emit("importedProposal", {
              status: "error",
            });
          }

          if (this.showToast) {
            this.$toast.add({
              severity: "success",
              summary: `Propostas importadas com sucesso`,
              detail: ``,
              life: 3000,
            });
          }
          return this.$emit("importedProposal", {
            status: "ok",
            destinationProposalId: response.id,
          });
        });
    },
    getProposalById() {
      this.searchProposalTerm = null;
      this.proposalList = [];
      if (this.proposalId == this.getProposalId) {
        return (this.errorMsg =
          "O id da proposta de destino tem de ser diferente do id da proposta de origem");
      }
      if (this.getProposalId == null || this.getProposalId == "") {
        return (this.errorMsg = "Introduza um id válido");
      }
      this.errorMsg = null;
      return proposalService
        .getBasicInformation(this.getProposalId)
        .then((response) => {
          if (!response) {
            return (this.errorMsg = `A proposta com o id ${this.getProposalId} não existe`);
          }
          return (this.proposalList = [response]);
        });
    },
    searchProposal() {
      this.getProposalId = null;
      this.proposalList = [];
      if (this.searchProposalTerm == null || this.searchProposalTerm == "") {
        return (this.errorMsg = "Introduza um termo válido");
      }
      this.errorMsg = null;
      return proposalService
        .search(this.searchProposalTerm)
        .then((response) => {
          if (!response || response.length == 0) {
            return (this.errorMsg = `Não existem tickets com termo ${this.searchProposalTerm}`);
          }
          return (this.proposalList = response);
        });
    },
    getEmailInfo(email) {
      if (!email) {
        return "";
      }
      let info = email.replace("@", " | ");
      info = info.replace(/[.,-/_]/g, " ");
      return info.substring(0, info.lastIndexOf(" "));
    },
    dateTime(date) {
      let auxDate = new Date(date);
      return `${getOnlyDate(auxDate)} ${getOnlyTime(auxDate)}`;
    },
    viewProposal(proposalId) {
      let route = this.$router.resolve({
        path: `/proposal-detail/${proposalId}`,
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>
