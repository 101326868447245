var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Toast',{style:({ width: _vm.$isMobile() ? '85vw' : '' })}),_c('Dialog',{style:({ width: _vm.$isMobile() ? '100vw' : '30vw' }),attrs:{"visible":_vm.show,"header":'Adicionar Contacto de Cliente',"modal":true,"closable":false},on:{"update:visible":function($event){_vm.show=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('Button',{staticClass:"p-button-success",attrs:{"label":'Guardar',"icon":"pi pi-check"},on:{"click":_vm.addContact}}),_c('Button',{staticClass:"p-button-danger",attrs:{"label":"Cancelar","icon":"pi pi-times"},on:{"click":_vm.cancel}})]},proxy:true}])},[_c('form',{staticClass:"p-col-12",staticStyle:{"min-height":"15vh"},attrs:{"name":"HelpdeskproposalsChangeStatus"},on:{"submit":function($event){$event.preventDefault();return _vm.addContact.apply(null, arguments)}}},[_c('div',{staticClass:"p-fluid p-formgrid p-grid"},[_c('div',{staticClass:"p-field p-col-12 p-md-12 p-mt-4"},[_c('span',{staticClass:"p-float-label"},[_c('Dropdown',{class:['form-control'],attrs:{"name":"type","options":['Comercial', 'Gestor', 'Pre-venda'],"showClear":true},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),_c('label',{attrs:{"for":"type"}},[_vm._v("Selecionar Tipo")])],1)]),_c('div',{staticClass:"p-field p-col-12 p-md-12 p-mt-2"},[_c('span',{staticClass:"p-float-label"},[_c('InputText',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
                { 'p-invalid': _vm.errors.has('name') },
                'form-control',
              ],attrs:{"name":"name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('label',{attrs:{"for":"name"}},[_vm._v("Nome")])],1),(_vm.errors.has('name'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" Nome é obrigatório ")]):_vm._e()]),_c('div',{staticClass:"p-field p-col-12 p-md-12 p-mt-2"},[_c('span',{staticClass:"p-float-label"},[_c('InputText',{directives:[{name:"validate",rawName:"v-validate",value:(!_vm.telephone ? 'required' : ''),expression:"!telephone ? 'required' : ''"}],class:[
                { 'p-invalid': _vm.errors.has('email') },
                'form-control',
              ],attrs:{"type":"email","name":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('label',{attrs:{"for":"email"}},[_vm._v("Email")])],1),(_vm.errors.has('email'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" Email é obrigatório ")]):_vm._e()]),_c('div',{staticClass:"p-field p-col-12 p-md-12 p-mt-2"},[_c('span',{staticClass:"p-float-label"},[_c('InputNumber',{directives:[{name:"validate",rawName:"v-validate",value:(!_vm.email ? 'required' : ''),expression:"!email ? 'required' : ''"}],class:[
                { 'p-invalid': _vm.errors.has('telephone') },
                'form-control',
              ],attrs:{"name":"telephone","useGrouping":false},model:{value:(_vm.telephone),callback:function ($$v) {_vm.telephone=$$v},expression:"telephone"}}),_c('label',{attrs:{"for":"telephone"}},[_vm._v("Telefone")])],1),(_vm.errors.has('telephone'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" Telefone é obrigatório ")]):_vm._e()])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }