<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog group="SurveyRemoveCanceled" />
  </div>
</template>
<script>
import surveyService from "../services/surveys.service";

export default {
  name: "SurveyRemoveCanceled",
  props: ["show", "showToast", "patId", "patName"],
  watch: {
    show() {
      if (this.show === true) {
        this.confirmRealized();
      }
    },
  },
  methods: {
    confirmRealized() {
      let message = `Tem a certeza que pretende remover o survey
      do Pat ${this.patId} - ${this.patName} de cancelado?`;
      this.$confirm.require({
        key: "SurveyRemoveCanceled",
        header: `Remover Survey de Cancelado`,
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          return this.removeCanceled();
        },
        reject: () => {
          return this.$emit("cancel");
        },
      });
    },
    removeCanceled() {
      return surveyService.removeCancelSurvey(this.patId).then((response) => {
        if (!response) {
          if (this.showToast) {
            this.$toast.add({
              severity: "error",
              summary: `Erro ao remover o survey de cancelado`,
              detail: "",
              life: 3000,
            });
          }
          return this.$emit("surveyRemoveCanceled", {
            status: "error",
            currentStatus: {},
          });
        }

        if (this.showToast) {
          this.$toast.add({
            severity: "success",
            summary: `Survey Removido de Cancelado`,
            detail: `Survey com o pat ${this.patId} mudou o estado para ${response.surveyStatus}`,
            life: 3000,
          });
        }
        return this.$emit("surveyRemoveCanceled", {
          status: "ok",
          currentStatus: {
            patStatus: response.patStatus,
            surveyStatus: response.surveyStatus,
          },
        });
      });
    },
  },
};
</script>
