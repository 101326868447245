<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog group="dialogProposalDeleteNote" />
  </div>
</template>
<script>
import proposalService from "../../services/proposal.service";

export default {
  name: "ProposalDeleteNote",
  props: ["deleteNote", "proposalId", "noteId", "showToast"],
  watch: {
    deleteNote() {
      if (this.deleteNote === true) {
        return this.confirmDelete();
      }
    },
  },
  methods: {
    confirmDelete() {
      let title = "Eliminar Nota";
      let message = "Tem a certeza que pretende eliminar esta nota?";

      this.$confirm.require({
        key: "dialogProposalDeleteNote",
        header: title,
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          return this.deleteProposalNote();
        },
        reject: () => {
          return this.$emit("cancel");
        },
      });
    },
    deleteProposalNote() {
      return proposalService
        .removeNote(this.proposalId, this.noteId)
        .then((response) => {
          if (!response) {
            if (this.showToast) {
              this.$toast.add({
                severity: "error",
                summary: `Erro ao eliminar a nota`,
                detail: "",
                life: 3000,
              });
            }
            return this.$emit("proposalNoteDeleted", {
              status: "error",
              currentStatus: this.budgeted,
            });
          }

          if (this.showToast) {
            this.$toast.add({
              severity: "success",
              summary: `Nota eliminada`,
              detail: "Nota eliminada com sucesso",
              life: 3000,
            });
          }
          return this.$emit("proposalNoteDeleted", {
            status: "ok",
            removed: response.removed,
          });
        });
    },
  },
};
</script>
