<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog group="dialogSetStatus" />
  </div>
</template>
<script>
import proposalService from "../../services/proposal.service";

export default {
  name: "SetStatus",
  props: ["newStatusId", "proposalId", "menssage", "proposalName", "showToast"],
  watch: {
    newStatusId() {
      if (this.newStatusId !== false) {
        return this.confirmSetStatus();
      }
    },
  },
  methods: {
    confirmSetStatus() {
      let message = "";
      let title = "";
      if (this.newStatusId === 6) {
        message = `Tem a certeza que pretende cancelar a
        proposta ${this.proposalId} - ${this.proposalName}?`;
        title = `Cancelar Proposta?`;
      } else if (this.newStatusId === 2) {
        message = `Tem a certeza que pretende retomar a
        proposta ${this.proposalId} - ${this.proposalName}?`;
        title = `Retomar Proposta?`;
      } else if (this.newStatusId === 4) {
        message = `Tem a certeza que pretende retirar de pendende a
        proposta ${this.proposalId} - ${this.proposalName}?`;
        title = `Retirar Proposta Pendente?`;
      } else if (this.newStatusId === 1) {
        message = `Tem a certeza que pretende mudar o estado da
        proposta ${this.proposalId} - ${this.proposalName} para aguardar resposta?`;
        title = `Aguardar Resposta?`;
      }

      this.$confirm.require({
        key: "dialogProposalSetBudget",
        header: title,
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          return this.changeStatus();
        },
        reject: () => {
          return this.$emit("cancel");
        },
      });
    },
    changeStatus() {
      return proposalService
        .setStatus(this.proposalId, { status_id: parseInt(this.newStatusId) })
        .then((response) => {
          if (!response) {
            if (this.showToast) {
              this.$toast.add({
                severity: "error",
                summary: `Erro ao mudar o estado proposta`,
                detail: "",
                life: 3000,
              });
            }
            return this.$emit("changedProposalStatus", {
              status: "error",
              currentStatus: "",
            });
          }

          if (this.showToast) {
            this.$toast.add({
              severity: "success",
              summary: `Alterado estado da Proposta`,
              detail: "",
              life: 3000,
            });
          }
          console.log(response);
          return this.$emit("changedProposalStatus", {
            status: "ok",
            currentStatus: response.status,
            currentStatusId: response.status_id,
          });
        });
    },
  },
};
</script>
